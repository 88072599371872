
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="26" height="99" viewBox="0 0 26 99" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 4.49997L18.5 8.8301V0.169846L26 4.49997ZM0.25 61.5V16.5H1.75V61.5H0.25ZM13 3.74997H19.25V5.24997H13V3.74997ZM0.25 16.5C0.25 9.45834 5.95837 3.74997 13 3.74997V5.24997C6.7868 5.24997 1.75 10.2868 1.75 16.5H0.25Z" fill="url(#paint0_linear_16292_203820)"/>
<path d="M26 94.5L18.5 90.1698V98.8301L26 94.5ZM0.25 33.5V82.5H1.75V33.5H0.25ZM13 95.25H19.25V93.75H13V95.25ZM0.25 82.5C0.25 89.5416 5.95837 95.25 13 95.25V93.75C6.7868 93.75 1.75 88.7132 1.75 82.5H0.25Z" fill="url(#paint1_linear_16292_203820)"/>
<defs>
<linearGradient id="paint0_linear_16292_203820" x1="2.04167" y1="33" x2="26" y2="33" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
<linearGradient id="paint1_linear_16292_203820" x1="2.04167" y1="64" x2="26" y2="64" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ClaimTodoApprovedDeniedSolidArrow',
    inheritAttrs: true,
  }
  </script>
